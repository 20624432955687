@import "../../scss/bootstrap";

.AppSidebar {
  background-color: $body-bg;
  text-align: center;

  .logo {
    display: inline-block;
    font-weight: normal;
    margin-top: 1.9em;
    // margin-left: -1em;

    img {
      width: 120px;
      margin-top: -0.5em;
    }

    h1 {
      font-size: 2em;
      color: $color-primary;
    }

    h1,
    h2 {
      margin: 0;
    }

    // h1::before {
    //   content: ">";
    //   opacity: 0.4;
    //   font-weight: 100;
    // }

    h2 {
      display: none;
      font-weight: lighter;
      opacity: .7;
      font-size: 1.1rem;
      margin-top: -.4em;
      padding-left: 4.5rem;
    }
  }

  nav.menu {
    margin-top: 3em;

    a {
      position: relative;
      display: flex;
      padding: 1rem;
      color: $color-dark;
      text-decoration: none;
      font-size: 1.2rem;
      transition: all .15s ease;

      .icon {
        margin-right: 0.5em;
        width: 1.8em;
        height: 1.8em;
        text-align: center;
        display: inline-block;
        margin-top: -2px;
        padding-top: 1px;
        border-radius: 40%;
        background-color: rgba($color-primary, 0);
        transition: all .3s ease;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 2em;
        margin-left: -3em;
        margin-top: 0.7em;
        background-color: $color-primary;
        // border-radius: 0 5px 5px 0;
        box-shadow: 8px 0 8px rgba(#000, .2);
        left: 0;
        opacity: 0;
        transition: all .3s ease;
      }
    }

    a.is-active {
      // transform: scale(1.1) ;
      font-weight: 500;

      .icon {

        background-color: $color-primary;
        border-radius: 50%;
        // padding: 1em;

      }

      svg {
        color: $body-bg;
      }

      padding-left: 1.3rem;
      color: $body-color;

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 2em;
        margin-left: -1em;
        margin-top: 0.7em;
        background-color: $color-primary;
        // border-radius: 0 5px 5px 0;
        box-shadow: 8px 0 8px rgba(#000, .2);
        left: 0;
        opacity: 1;
      }
    }
  }

  .submenu h3 {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.8em;
    padding-left: 1.5em;
    padding-right: 1em;
    opacity: 0.3;
  }
}