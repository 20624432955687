// @import "../../style/variables.scss";
@import "../../scss/bootstrap";

$messageBorderRadius: 8px;

.delete-button {
  position: relative;

  svg path {
    fill: #fff !important;
  }
}

.delete-button button {
  transition: border-radius .2s ease;
}

.message {
  position: absolute;
  top: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 0.1em;
  margin-top: -1px;
  font-size: 0.9em;
  pointer-events: none;
  background-color: lighten($danger, 40%);
  border: 2px solid $danger;
  color: $white;
  z-index: 1000;
  opacity: 0;
  transition: all .2s ease;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, .15);
  overflow: hidden;


  p {
    color: $danger;
    min-width: 10em;
    text-align: center;
    margin-bottom: 0.5em;
  }

  button {
    font-size: 1em;
    min-width: 8em;
    border-radius: 0;
  }

}


.delete-button.confirm>button {
  // border-radius: 3em;

  cursor: default;
}

.delete-button.confirm .message {
  opacity: 1;
  pointer-events: all;
}

// Opening Right
.delete-button.confirm.open-right>button {
  border-radius: 2em 3px 3px 2em;
}

.delete-button.open-right .message {
  border-radius: 3px $messageBorderRadius $messageBorderRadius 3px;
  left: 90%;
  // margin-top: -1em;
}

.delete-button.confirm.open-right .message {
  left: 105%;
}

// Opening Left

.delete-button.confirm.open-left>button {
  border-radius: 3px 2em 2em 3px;
}

.delete-button.open-left .message {
  border-radius: $messageBorderRadius 0 $messageBorderRadius $messageBorderRadius;
  right: 90%;
}

.delete-button.confirm.open-left .message {
  right: 105%;
}