h1.title {
  font-size: 2em;
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 2em;
}

@mixin box {
  border-radius: 15px;
  padding: 2em;
  // min-height: 20em;
  margin-bottom: 2em;
  background-color: $box-bg-color;
  // border: 1px solid $color-dark;
  // border: 1px solid #ddd;
  box-shadow: 4px 3px 5px rgba($color-dark, .1);
}

.box {
  @include box
}

.box-paddingless {
  @include box;
  padding: 0;
  overflow: hidden;
}

table.box {
  padding: 0;
}

.box-title {
  .icon {
    color: #ccc;
  }

  margin: 0 .2em 1em .2em;
}

.id {
  font-family: monospace;
  font-size: 0.8em;
  font-weight: bolder;
  border-radius: 3px;
  background-color: #333;
  color: #fff;
  padding: .2em .3em;
  box-shadow: 1px 1px 2px rgba(blue, .2);
}

h1+.subnav {
  margin-bottom: 1rem;
  padding-left: 0.5rem;

  // a {
  //   color: #000;
  //   background-color: rgba(#000, 0);
  //   border-radius: 20px;
  //   transition: all .1s ease;
  // }

  // a.active {
  //   background-color: #000;
  //   color: #fff;
  // }
}


label {
  font-weight: 500;
}

fieldset .ant-form-item {
  max-width: 600px;
}

fieldset.box legend {
  display: flex;
  align-items: center;
  color: $nav-link-color;
  font-weight: 100;
  margin-top: -0.5em;
  margin-bottom: 1em;
}

fieldset.box legend {}

fieldset.action-bar {
  @include box;

  display: flex;
  justify-content: space-between;

  position: sticky;
  bottom: -2em;
  z-index: 1020;
  margin-bottom: 0;
}

table.table th {
  font-size: 0.8em;
  font-weight: 700;
}

table.table th.id {
  width: 5em;
}

table.table th:first-child,
table.table th.id:first-child {
  border-radius: 10px 0 0 0;
}

table.table th:last-child,
table.table th.id:last-child {
  border-radius: 0 10px 0 0;
}

table.table thead th {
  background-color: lighten($color-primary, 70%);
  color: $color-primary;
}

// table,
// table tfoot,
// table tfoot tr:last-child,
// table tfoot tr:last-child td:first-child {
//   border-bottom-left-radius: 10px;
// }

table.table {
  border-radius: 10px;
  // overflow: hidden;
}

table.tfoot {
  border-radius: 0 0 10px 10px;
}

table.tfoot tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

table.tfoot tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}


input.input-without-controls::-webkit-outer-spin-button,
input.input-without-controls::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.input-without-controls[type=number] {
  -moz-appearance: textfield;
}




/* ANTD overrides */

// .and-alert {
//   padding: 10px 14px;
// }

.ant-alert-message b,
.ant-alert-description b {
  font-weight: 500;
}