@import "../../scss/bootstrap";

.multiselect {
  position: relative;
  padding-left: 0.5rem;
}

.focus {
  // color: #212529;
  background-color: $input-focus-bg;
  border-color: $input-focus-color;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba($primary, 0.2);
}

.values {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  // padding: 0.5em;
  // border: 1px solid #ddd;
  // border-radius: 0.25rem 0.25rem 0 0;
  // box-shadow: 0 0 5px rgba(#333, .1) inset;

  &>div {
    display: flex;
  }

  .value {
    position: relative;
    margin: 0 5px;

    transition: all .1s ease;

    &:hover {
      opacity: 0.9;
      transform: scale(0.95);
      cursor: no-drop;
    }

    &:first-child {
      margin-left: 0;
    }

    // &>button {
    //   top: -5px;
    //   right: -10px;
    //   position: absolute;
    //   display: block;
    //   border-radius: 15px;
    //   border: 1px solid #fff;
    //   height: 20px;
    //   line-height: 10px;
    //   width: 20px;
    //   background-color: rgba(var(--bs-danger-rgb),1)!important;
    //   color: #fff;
    //   z-index: 10;
    // }
  }



}



.suggestions {
  position: absolute;
  overflow: auto;
  background-color: $body-bg;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .1), 10px 10px 20px rgba(0, 0, 0, .2);
  border-radius: 0.5rem;
  border: 1px solid $input-border-color;
  z-index: 100;
  padding: 0.75rem;
  max-height: 20em;
  margin-top: 1rem;
  margin-left: -0.75rem;
  min-width: 10em;
  width: 100%;
}

.suggestions li {
  // padding: 0.2em 0.8em;
  color: $body-color;
  transition: all .3s ease;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(#999, .2);
  border-radius: 3px;
}

.suggestions li:last-child {
  border-bottom: none;
}

.suggestions li:hover {
  background-color: $color-gray;
  // color: $color-light;
}

li.selected,
li.selected:hover {
  background-color: $primary;
  color: $body-color;
  // color: #000;
  /* font-weight: bolder; */
}

.input {
  flex-grow: 1;
  flex-basis: 1px;

  // background-color: red;
  position: relative;

  input {
    width: 100%;
    min-width: 1px;
    border: none;
    outline: none;
    background-color: transparent;
    color: $body-color;
  }


}


.disabled,
.disabled.focus {
  background-color: $input-disabled-bg;
  border-color: $input-disabled-bg;
  box-shadow: none;
}

.is-empty {
  // .values {
  //   display: none;
  // }

}