@import '../../scss/theme.scss';

.title {
  // font-size: 2em;
  font-weight: 400;
  color: lighten($color-primary, 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  // justify-content: center;
}