// @import '@csstools/normalize.css';
// @import "normalize.css";


@import "./bootstrap";


@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,400;0,500;1,400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@200;300;400;500;700&display=swap');


.App,
td,
th {
  font-family: 'MuseoModerno', sans-serif;
  font-family: 'Raleway', sans-serif;
  // color: #e6e9ed;
}

.app-grid {
  display: grid;
  // background-color: #1f1d2c;
  background-color: $body-bg;
  border-left: 10px solid $color-primary;
  height: 100vh;
  grid-template-columns: 15rem 1fr;
  grid-template-rows: 1fr;
  // grid-template-rows:  100vh;
  grid-template-areas: "sidebar main";
}

.app-sidebar {
  grid-area: sidebar;
  height: 100vh;
  padding: 2em 1em;
}

.app-main {
  grid-area: main;
  padding: 2rem 2rem;
  overflow: auto;
  // background-color: $color-primary;
  // border-left: 1px solid #3f4050;
}




@import "./components.scss";