@import "../../scss/bootstrap";

.controlbar {
  display: flex;
  justify-content: space-between;

  position: sticky;
  bottom: -2.05em;
  z-index: 1020;
  margin-bottom: 0;

  padding: 1.2em 2em;

  transition: all .4s cubic-bezier(.47, 1.64, .41, .8);
  border-top: 1px solid transparent;
}

.sticked {
  border-radius: 0;
  border-top: 1px solid $table-border-color ;
  // border-left: 1px solid $table-border-color ;
  background-color: rgba($body-bg, 0.9);
  padding: 1.2em 4em;
  margin-left: -2em;
  margin-right: -2em;
  box-shadow: 40px 0 40px rgb(0 0 0 / 30%);
  transition: all .1s ease-out;
  backdrop-filter: blur(8px);

  // border-radius: 0.5rem 0 0 0;
}