@import "../../scss/bootstrap";

.selected,
.suggestion {
  display: flex;
  align-items: center;
}

.selected {
  font-size: 0.8em;
}

.suggestion {
  padding: 0.5em 1em;
  color: $body-color;
}

.active {
  color: #fff;
}