@import "../../scss/bootstrap";

$paddingTop: 1.5rem;
$marginBottom: 3rem;

.top {
  // position: absolute;
  // top: -2.05rem;
  margin: -0.5rem 0 $marginBottom 0;
  padding: $paddingTop 0 0.1rem 0;
  border-bottom: 1px solid rgba($input-border-color, 0);
  background-color: rgba($body-bg, 0);
  transition: all .2s ease;
  font-size: 1.5rem;
  z-index: 100;
  font-weight: 100;
}

.sticked {
  margin: -0.5rem -2rem $marginBottom -2rem;
  padding: $paddingTop 2rem 1rem 2rem;
  // background-color: rgba(#2e303f, 1);
  background-color: rgba($body-bg, 0.9);
  border-bottom: 1px solid $input-border-color;
  box-shadow: 30px 0 30px rgb(0 0 0 / 20%);
  backdrop-filter: blur(15px);
  font-size: 1.2rem;

}

.username {
  font-size: 0.8em;
  font-weight: 300;
  padding: 0 0.5em;
}

.agency {
  font-size: 0.8em;
  font-weight: 400;
  padding: 0 0.5em;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    background-color: $color-primary;
    color: #fff;
  }
}