.event-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  margin-left: 10px;
  text-decoration: none;
  font-size: 0.8em;
}

.event-error {
  // outline: 3px dashed red;

  // background: repeating-linear-gradient(-45deg,
  //     transparent,
  //     transparent 10px,
  //     red 10px,
  //     red 20px);
  // background-size: 10px 20px;
  // overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 0 0 8px 8px;
    height: 10px;
    width: 100%;
    margin-top: -7px;
    background: repeating-linear-gradient(-45deg,
        rgba(lighten(red, 28%), .9),
        rgba(lighten(red, 28%), .9) 10px,
        red 10px,
        red 20px);
  }
}

.event-warning {
  // outline: 2px dashed orange;

  // background: repeating-linear-gradient(-45deg,
  //     transparent,
  //     transparent 10px,
  //     orange 10px,
  //     orange 20px);

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 0 0 8px 8px;
    height: 10px;
    width: 100%;
    margin-top: -7px;
    background: repeating-linear-gradient(-45deg,
        rgba(lighten(orange, 28%), .9),
        rgba(lighten(orange, 28%), .9) 10px,
        orange 10px,
        orange 20px);
  }
}

// .event-canceled {
//   outline: 2px dotted #DE6E4B;
//   background-color: transparent !important;
//   color: #DE6E4B;
// }