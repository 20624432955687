@import "../../scss/theme";

.kpis {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
  // margin: 0 0 0 1rem;
  // padding: 0;
  // width: 100%;
  // height: 100%;
  // list-style: none;
  // font-size: 1.5rem;
  // font-weight: 600;
  // color: #fff;
  // text-align: center;
  // background-color: #000;
  // border-radius: 0.5rem;
  // box-shadow: 0 0 0.5rem #000;
  margin-bottom: 1rem;
}

.kpi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 1rem;
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  list-style: none;
  font-size: 1.5rem;
  font-weight: 300;
  color: #fff;
  text-align: center;
  background-color: $color-secondary;
  border-radius: 0.5rem;
  // box-shadow: 0 0 0.5rem #000;
}

.kpi:first-child {
  margin-left: 0;
}

.kpi:last-child {
  margin-right: 0;
}

.kpiLabel {
  font-size: 0.75rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
}